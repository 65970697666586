import { axiosCall } from './'

// Model API
export const modelAPI = {
  check: async () => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/`,
    })
  },
  getModels: async (accountId, modelName) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/${accountId}/${modelName}`,
    })
  },
  getModel: async (accountId, modelName, id) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/${accountId}/${modelName}/${id}`,
    })
  },
  postModel: async (accountId, modelName, data) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/${accountId}/${modelName}`,
      data: data,
    })
  },
  putModel: async (accountId, modelName, id, data) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/${accountId}/${modelName}/${id}`,
      data: data,
    })
  },
  patchModel: async (accountId, modelName, data) => {
    return await axiosCall({
      method: 'Patch',
      url: `/api/v1/${accountId}/${modelName}`,
      data: data,
    })
  },
  deleteModel: async (accountId, modelName, id) => {
    return await axiosCall({
      method: 'Delete',
      url: `/api/v1/${accountId}/${modelName}/${id}`,
    })
  },
}

// Events API
export const eventsAPI = { // @todo - //note - temporary non concurrent version of events demo, will overwrite all events, adapt later to true CRUD
  postEvents: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/events`,
      data: data,
    })
  },
  getEvents: async (id = '') => { // @todo - //note - temporary non concurrent version of events demo
    return await axiosCall({
      method: 'Get',
      url: `/events/${id}`,
    })
  },
}

// Authorize API
export const authAPI = {
  postSignup: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/signup`,
      data: data,
    })
  },
  postLogin: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/login`,
      data: data,
    })
  },
  postLogout: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/logout`,
      data: data,
    })
  },
  getStatus: async () => {
    return await axiosCall({
      method: 'Get',
      url: `/status`,
    })
  },
}

// Address API
export const addressAPI = {
  getAddressListByEntityId: async (entity_id) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/contact-address/list/${entity_id}`,
    })
  },
  createAddress: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/contact-address/`,
      data: data,
    })
  },
  updateAddress: async (addressId, data) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/contact-address/${addressId}`,
      data,
    })
  },
  deleteAddress: async (id) => {
    return await axiosCall({
      method: 'Delete',
      url: `/api/v1/contact-address/${id}`,
    })
  },
  getAddressById: async (id) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/contact-address/${id}`,
    })
  },
}
