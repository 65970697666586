import React from 'react'
import { Link } from 'react-router-dom'
import {
  CAlert,
  CAlertHeading,
  CAlertLink,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CLink,
} from '@coreui/react'
import { DocsExample } from 'src/components'

const TermsAndConditions = () => {
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Terms And Conditions</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <CAlert color="primary"><p>Terms...</p><ul><li></li><li></li><li></li></ul></CAlert>
            <CAlert color="secondary"><p>Conditions...</p><ul><li></li><li></li><li></li></ul></CAlert>
            {/* <CAlert color="success"></CAlert>
            <CAlert color="danger"></CAlert>
            <CAlert color="warning"></CAlert> */}
            <CAlert color="warning">
              <Link to="/register">
                <CLink type="link" className="px-2">
                  Register for an account.
                </CLink>
              </Link>
            </CAlert>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default TermsAndConditions
