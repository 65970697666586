import axios from 'axios'

// axios.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       //place your reentry code
//       console.log(error.response.data)
//       return error.response.data
//     }
//     return error
//   },
// )

export const axiosCall = async (config = {}) => {
  if (config.url.startsWith(`/api`)) {
    let token = ``;
    config.headers = {
      authorization: `Bearer ${token}`,
      'Cache-Control': 'max-age=0',
    }
  }
  if (config.timeout === undefined) {
    config.timeout = 1000 * 60 * 1 // 1 minute timeout because if we don't give enough time then post will cancel and page will refresh
  }
  return await axios(config)
    .then(({ status, statusText, data } = {}) => {
      return { ...data, status, statusText }
    })
    .catch((error, extra) => {
      return error.response
    })
}
