export const logo = [
  '608 134',
  `
  <title>logo</title>
  <g>
    <g style="fill:#00a1ff">
</g>
    <g style="fill:#3c4b64">
      <g>
 </g>
      <g>
 </g>
    </g>
  </g>
`,
]
