import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
  cilContact,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatars/me.jpg'

import { authAPI, modelAPI } from '../../services/api-services'

const checkExpires = (datetimestringzulu) => {
  // 2023-09-08T18:23:25.801Z
  let result = 0;
  let expireDate;
  let nowDate;
  try {
    if (!datetimestringzulu) { datetimestringzulu = new Date().toISOString() }
    expireDate = new Date(datetimestringzulu);
    nowDate = new Date();
    result = (((expireDate - nowDate) / 1000) / 60).toFixed(2);
    result; // minutes until expires
  } catch {
    result = -1;
  }
  console.log("Cookies expires...");
  console.log(datetimestringzulu);
  console.log("Date/Time now...");
  console.log(nowDate.toISOString());
  console.log("Minutes remaining...");
  console.log(result);
  try { localStorage.setItem('slp-token-mr', result); } catch { }
  try { localStorage.getItem('slp-token-mr'); } catch { }
  return result;
}

const AppHeaderDropdown = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const handleLogoutClicked = () => {
    dispatch({ type: 'set', isLoggedIn: false })
    dispatch({ type: 'set', user: {} })
    dispatch({ type: 'set', data: {} })
    authAPI.postLogout()
  }

  useEffect(() => {
    const checkStatus = async () => {
      console.log(`Checking status..`)
      let checkStatusResult = await authAPI.getStatus()
      // console.log("checkStatusResult")
      // console.log(checkStatusResult)
      if (checkExpires(checkStatusResult?.cookie?.expires || null) < -1000) {
        handleLogoutClicked()
      }
      dispatch({ type: 'set', ...checkStatusResult })
      dispatch({ type: 'set', data: { ...checkStatusResult } })
    }
    checkStatus()
    let timer = setInterval(checkStatus, 60 * 1000 * 1)
    return () => {
      clearInterval(timer)
    }
  }, [isLoggedIn]) // location  --  if location is added to this array, it will check between every route change

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar
          src={avatar8}
          size="md"
          style={isLoggedIn ? { border: '3px solid green' } : { border: '3px solid red' }}
        />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        {/* <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}

        {/* <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader> */}
        {isLoggedIn && (
          <CDropdownItem href="/#/profile">
            <CIcon icon={cilContact} className="me-2" />
            Profile
          </CDropdownItem>
        )}

        {/* <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
        {/* <CDropdownItem href="#">
          <CIcon icon={cilFile} className="me-2" />
          Projects
          <CBadge color="primary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownDivider />
        {!isLoggedIn && (
          <CDropdownItem href="/#/login">
            <CIcon icon={cilLockLocked} className="me-2" />
            Sign In
          </CDropdownItem>
        )}
        {!isLoggedIn && (
          <CDropdownItem href="/#/register">
            <CIcon icon={cilCreditCard} className="me-2" />
            Register
          </CDropdownItem>
        )}
        {isLoggedIn && (
          <CDropdownItem onClick={handleLogoutClicked} href="#">
            <CIcon icon={cilLockLocked} className="me-2" />
            Sign Out
          </CDropdownItem>
        )}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
