import { createStore } from 'redux'

const initialState = {
  sidebarShow: false,
  isLoggedIn: false,
  user: { first_name: '', last_name: '', email: '' },
  data: { message: '', apiToken: '', apiRefreshToken: '' },
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
